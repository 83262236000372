import React from 'react';
import Modal from 'react-modal';
import CloseX from '../assets/mars-close.svg';
 
const VideoModal = ({isOpen, activeVideo, closeModal}) => (
    <Modal 
        className="video-modal"
        isOpen={isOpen} 
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
    >
        <div className="modal-interior">
            <button tabIndex="0" className="close-modal" onClick={closeModal} aria-label="close modal window">
                <CloseX />
            </button>
            <div className="video-wrapper" dangerouslySetInnerHTML={{ __html: activeVideo }} />
        </div>
    </Modal>
);


Modal.setAppElement('#___gatsby');
export default VideoModal;