import React from 'react';
import VideoModal from './VideoModal';
import PlayButton from '../assets/play-button.svg';
class VideoModalTrigger extends React.Component{
    constructor(props){
        super(props);
        this.state={
            activeVideo: null,
            modalOpen: false
        }
    }
    
    clickVideo = (e) => {
        e.preventDefault();
        this.setState({ 
            modalOpen: true,
            activeVideo: this.props.videoCode
        });
        document.body.classList.add('lockup');
        document.documentElement.classList.add('lockup');
    }

    closeModal = (e) => {
        e.preventDefault();
        this.setState({
            modalOpen: false,
            activeVideo: null
        })
        document.body.classList.remove('lockup');
        document.documentElement.classList.remove('lockup');
    }

    render(){  
        let videoImg = this.props.videoThumb.url.split('/').pop();
        return(
            <div className="video-trigger-wrap">
                <button className="video-trigger" onClick={this.clickVideo} >
                    <img className="video-thumb" src={`/${videoImg}`} alt={this.props.videoThumb.alt} />
                    <PlayButton className="play-button" />
                </button>
                {this.state.modalOpen && 
                    <VideoModal 
                        isOpen={this.state.modalOpen}
                        closeModal={this.closeModal}
                        activeVideo={this.state.activeVideo} 
                    />
                }
            </div>

        )
    }
}


export default VideoModalTrigger;